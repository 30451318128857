export const BORING_HINTS: string[] = [
  's',

  'he',
  'she',
  'his',
  'her',
  'its',

  'this',
  'that',
  'those',
  'these',
  'their',
  'they',
  'then',
  'them',

  'which',
  'where',
  'when',
  'who',
  'while',

  'be',
  'are',
  'were',
  'has',
  'had',
  'have',
  'can',
  'could',

  'so',

  'yes',
  'no',
  'not',
  'any',
  'only',
  'some',
  'all',
  'many',
  'most',
  'also',
  'both',
  'such',
  'same',
  'would',
  'however',
];
